// @ts-nocheck

/* eslint-disable */
// import HRTC from "../package/sdk_1.2.1/hrtc";
// import HRTC from "../HWHRTC-Package/sdk_1.4.0/hrtc";

import HRTC from "../HWHRTC-PackageNew/lib/hrtc";

console.log("START---HRTC Version", HRTC.VERSION);
class WebRtc {
  videoInputs = [];
  audioOutputs = [];
  audioInputs = [];

  cameraStatus = "back";

  client = null;
  stream = null;
  constructor(appId, domain) {
    // this.videoInputs = [];
    // this.audioOutputs = [];

    this.client = HRTC.createClient({
      appId,
      // domain,
    });

    console.log("------==========", this.client);
  }
  /*************  外部接口  **************** */
  // 1. 检测浏览器是否兼容SDK
  checkCompatibility() {
    let isMatch = HRTC.checkSystemRequirements();
    if (isMatch) {
      console.log("兼容");
    } else {
      console.log("不兼容");
    }
    return isMatch;
  }

  // 检测浏览器是否兼容 SDK
  async isBrowserSupport() {
    let check = false;
    try {
      check = await HRTC.checkSystemRequirements();
      console.warn("browser isSupport: " + check);
    } catch (error) {
      console.error(`check browser isSupport error: ${error}`);
      console.error(
        `check browser isSupport error: ${error.getCode()} - ${error.getMsg()}`
      );
      if (error.getCode() !== 90100025) {
        console.error(`browser Support part ability of RTC`);
        check = true;
      }
    }
    return check;
  }

  // 获取设备信息
  getDeviceInfo() {
    return Promise.all([
      HRTC.getMicrophones()
        .then((deviceInfo) => this.getAudioInput(deviceInfo))
        .catch(this.printDeviceError),
      HRTC.getCameras()
        .then((deviceInfo) => this.getVideoInput(deviceInfo))
        .catch(this.printDeviceError),
      HRTC.getSpeakers()
        .then((deviceInfo) => this.getAudioOutput(deviceInfo))
        .catch(this.printDeviceError),
    ]);
  }
  getVideoInput(deviceInfo) {
    this.videoInputs = [];
    for (let item of deviceInfo) {
      this.videoInputs.push(item.toJSON());
    }
    this.videoInputs.sort((a, b) => a.label.localeCompare(b.label));
    this.videoInput = deviceInfo[0];
    console.info("!!!!!!!!!!!!!videoInput: ", this.videoInput);
  }

  async getCameraDeviceId(distCameraStatus: "back" | "front") {
    let deviceInfo = await HRTC.getCameras();
    // TODO 加排序 deviceInfo 按照从小到大排序，
    deviceInfo.sort((a, b) => a.label.localeCompare(b.label));
    let _distId = null;
    for (const index in deviceInfo) {
      const item = deviceInfo[index];
      // console.log(
      //   "iterator-------",
      //   index,
      //   item.kind,
      //   item.label,
      //   item.toJSON()
      // );
      if (0 <= item.label.toLowerCase().indexOf(distCameraStatus)) {
        // if (_distKind == item.kind) {
        _distId = item.deviceId;
        this.cameraStatus = distCameraStatus;
        break;
      }
    }

    return _distId;
  }

  getAudioOutput(deviceInfo) {
    this.audioOutputs = [];
    for (let item of deviceInfo) {
      this.audioOutputs.push(item.toJSON());
    }

    this.audioOutput = deviceInfo[0];
    console.info("!!!!!!!!!!audioOutput: ", this.audioOutput);
  }
  getAudioInput(deviceInfo) {
    this.audioInputs = [];
    for (let item of deviceInfo) {
      this.audioInputs.push(item.toJSON());
    }

    this.audioInputs = deviceInfo[0];
    console.info("!!!!!!!!!!audioInputs: ", this.audioInputs);
  }

  // 获取摄像头麦克风等设备ID
  getDeviceId(type: string, deviceId: string): string {
    if (deviceId !== "default") {
      return deviceId;
    }
    let defaultDeviceId = "default";
    if (type === "microPhone") {
      const defaultDevice = this.audioInputs.find(
        (audioInput) => audioInput.deviceId === "default"
      );
      if (defaultDevice) {
        const matchedDevice = this.audioInputs.find(
          (audioInput) =>
            defaultDevice.label !== audioInput.label &&
            defaultDevice.label.endsWith(audioInput.label)
        );
        if (matchedDevice) {
          defaultDeviceId = matchedDevice.deviceId;
        }
      }
    } else if (type === "video") {
      const defaultDevice = this.videoInputs.find(
        (videoInput) => videoInput.deviceId === "default"
      );
      if (defaultDevice) {
        const matchedDevice = this.videoInputs.find(
          (videoInput) =>
            defaultDevice.label !== videoInput.label &&
            defaultDevice.label.endsWith(videoInput.label)
        );
        if (matchedDevice) {
          defaultDeviceId = matchedDevice.deviceId;
        }
      }
    } else if (type === "speaker") {
      const defaultDevice = this.audioOutputs.find(
        (audioOutput) => audioOutput.deviceId === "default"
      );
      if (defaultDevice) {
        const matchedDevice = this.audioOutputs.find(
          (audioOutput) =>
            defaultDevice.label !== audioOutput.label &&
            defaultDevice.label.endsWith(audioOutput.label)
        );
        if (matchedDevice) {
          defaultDeviceId = matchedDevice.deviceId;
        }
      }
    }
    return defaultDeviceId;
  }

  /**
   * 验证房间ID 用户ID是否合法
   * @param {roomId || userid} id
   * a-zA-Z0-9 规则  !/^[a-zA-Z0-9]+$/g.test(id)
   * a-zA-Z0-9_-  规则   !/^[a-zA-Z0-9_-]+$/g.test(id)
   */
  checkId(id) {
    if (
      (id || "").length < 1 ||
      (id || "").length > 64 ||
      !/^[a-zA-Z0-9]+$/g.test(id)
    ) {
      console.error("make sure roomId or userId");
      return false;
    } else {
      return true;
    }
  }
  // 3. 加入房间
  // role：用户角色，可以标识媒体方向，取值如下:
  //   0：joiner（发布并观看）。
  //   1：publisher（只发布不观看）。
  //   2：player（只观看不发布）。
  async joinRoom(userId, userName, signature, ctime, role, roomId) {
    try {
      if (this.checkId(roomId) && this.checkId(userId)) {
        // console.log("======", userId, userName, signature, ctime, role, roomId);
        // app_id + "+" + room_id + "+" + user_id + "+" + ctime;

        console.log(
          `65531a2afa163e598e681505b799e8dd+${roomId}+${userId}+${ctime}`
        );
        await this.client.join(roomId, {
          userId,
          userName,
          signature,
          ctime,
          role,
        });
        console.log("!!!!!!!!!!!!!!!!!!!加入成功");
      } else {
        console.log("!!!!!!!!!!!!!!!!!!!roomId OR userID 不合法");
      }
    } catch (e) {
      console.log(!!!!!!!!!!!!!!!!!!!"加入失败", e);
    }
  }

  // 离开房间
  async leave() {
    try {
      await this.client.leave();
    } catch (e) {
      console.log("退出失败", e);
    }
  }
  async switchDevice(deviceType: "audio" | "video") {
    const distCameraStatus = "back" == this.cameraStatus ? "front" : "back";
    let _distId = await this.getCameraDeviceId(distCameraStatus);

    if (_distId) {
      await this.stream.switchDevice(deviceType, _distId);
    }
  }
  // async switchDevice(deviceType: "audio" | "video") {
  //   let deviceInfo = await HRTC.getCameras();
  //   // TODO 加排序 deviceInfo 按照从小到大排序，
  //   for (const iterator of deviceInfo) {
  //     console.log("iterator-------", iterator.toJSON());
  //   }

  //   deviceInfo.sort((a, b) => a.label.localeCompare(b.label));

  //   const distCameraStatus = "back" == this.cameraStatus ? "front" : "back";
  //   let _distId = await this.getCameraDeviceId(distCameraStatus);

  //   // const _distKind =
  //   //   "back" == this.cameraStatus
  //   //     ? "videoinput camera2 1"
  //   //     : "videoinput camera2 0";

  //   // let _distId =
  //   //   "back" == this.cameraStatus
  //   //     ? deviceInfo[0].deviceId
  //   //     : deviceInfo[5].deviceId;
  //   // this.cameraStatus = distCameraStatus;
  //   let _distId = null;
  //   for (const index in deviceInfo) {
  //     const item = deviceInfo[index];
  //     console.log(
  //       "iterator-------",
  //       index,
  //       item.kind,
  //       item.label,
  //       item.toJSON()
  //     );
  //     if (0 <= item.label.toLowerCase().indexOf(distCameraStatus)) {
  //       // if (_distKind == item.kind) {
  //       _distId = item.deviceId;
  //       this.cameraStatus = distCameraStatus;
  //       break;
  //     }
  //   }
  //   if (_distId) {
  //     await this.stream.switchDevice(deviceType, _distId);
  //   }
  // }
  // 4.创建本地流并发布
  // elementId：HTML <div>标签 ID
  async pushStream(elementId, facingMode = "environment") {
    console.log("开始推流", facingMode);
    await this.getDeviceInfo();
    return new Promise((resolve, reject) => {
      this.stream = HRTC.createStream({
        audio: true,
        video: true,
        // facingMode: "environment",
        // cameraId: this.getDeviceId("video", this.videoInputs.deviceId),
        // microphoneId: "",
      });
      //
      this.stream.setVideoProfile("1080p_1");
      // stream.setVideoProfile("720p_1");

      this.stream
        .initialize()
        .then(() => {
          console.log("===创建本地流并发布");
          this.client.publish(this.stream);
          console.log("===创建本地流并发布01");
          this.stream.play(elementId, { muted: true }); //播放本地流
          console.log("===创建本地流并发布0", elementId);
          resolve();
        })
        .catch((e) => {
          console.log("===创建本地流并发布异常", e);
          reject(e.message);
        });
    });
  }
  // 收流端
  getStream(elementId) {
    console.log("START---收流端---", elementId);
    console.log("START---_onStreamAdd");
    this._onStreamAdd();
    this._onSubscribe(elementId);
  }
  // 本端离开房间
  // 当音视频通话结束时，调用此接口离开房间。
  leaveRoom() {
    this.client.leave();
  }
  /*************  私有函数  **************** */
  // 当收到服务器发送的“stream-added”事件通知时，可以订阅远端媒体。
  _onStreamAdd() {
    this.client.on("stream-added", (event) => {
      console.log("START---_onStreamAdd", event);
      const stream = event.stream;
      console.log("START---", event.stream);
      this.client.subscribe(stream, { video: true, audio: true });
    });
  }
  //
  /**
   *  订阅完成之后，本端会收到“stream-subscribed”事件通知，可设置对端窗口，播放对端音视频。
    * elementId：HTML <div>标签 ID。
    播放选项参数：
    objectFit：必选，string类型，取值包括contain、cover和fill。
    muted：可选，boolean类型，true表示静音，false表示不静音，默认值为false。
   */
  _onSubscribe(elementId) {
    this.client.on("stream-subscribed", (event) => {
      console.log("START--订阅完成之后, 本端会收到“stream-subscribed”事件通知");
      const stream = event.stream;
      // 本地播放远程流
      console.log("START--订阅完成之后,", event.stream);
      stream.play(elementId, { objectFit: "contain", muted: false });
      // 若是不想观看对端，则可取消订阅对端音视频
      // this.client.unsubscribe(stream);
    });

    // 远端流移除事件
    this.client.on("stream-removed", (evt) => {
      console.log("START-stream-removed", evt.stream.getUserId());
      evt.stream.close();
    });

    // 远端流更新事件
    this.client.on("stream-updated", (evt) => {
      console.log("START-stream-updated", evt.stream.getUserId());
    });
  }
  // 当远端离开房间，本端会收到“peer-leave”事件通知，清理远端用户的资源。
  _onPeerLeave() {
    this.client.on("peer-leave", (event) => {
      // just do something…
    });
  }
  // event.userId：对端用户标识，通过监听“peer-leave”事件获得。
  // 远端用户退出，本端同时会收到“stream-removed”事件通知，可在事件处理函数中，关掉视频窗口。
  _onRemove() {
    this.client.on("stream-removed", (event) => {
      // 通过stream对象调用close()方法，该方法会移除之前用“play”创建的video标签元素并关闭摄像头、麦克风。
      event.stream.close();
    });
  }
}

export default WebRtc;

// 1 0 * * * root /home/shell/backup.sh >/dev/null 2>&1 &
// 1 0 * * * root /home/shell/clear_backup.sh >/dev/null 2>&1 &
// 1 0 * * * root /home/shell/systemlog-upload-oss.sh >/dev/null 2>&1 &
